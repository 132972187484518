import { MediaAPI } from './media';
import { timeUtils } from "../helpers/timeUtils";
import utils from "../helpers/utils";
import { ScheduledReportsAPI } from "./analytics/reports";
import { AudiencesAPI } from "./audiences";
import { CampaignsAPI } from "./campaigns";
import { DiscountsAPI } from "./discounts";
import { RecipesAPI } from "./recipes";
import { SurveysAPI } from "./surveys";
import { UserAlertsAPI } from "./userAlerts";
import { UsersAPI } from "./users";
import { ReviewsAPI } from "./reviews";
import { GeneralAPI } from "./all";
import { PersonasAPI } from "./personas";

export * from "./user-settings";
export * from "./points-freeze-windows";

export * as AllAPI from "./all";
export * as UsersAPI from "./users";
export * as RecipesAPI from "./recipes";
export * as CampaignsAPI from "./campaigns";
export * as SurveysAPI from "./surveys";
export * as ReviewsAPI from "./reviews";
export * as PersonasAPI from "./personas";
export * from "./customer-chat";
export * as CollectiblesAPI from "./collectibles";

const methods = ['GET', 'POST', 'PUT', 'DELETE'] as const;

export const API = {
    fetch: fetchAPI,
    asyncWrap,

    ...UsersAPI,
    ...UserAlertsAPI,

    ...RecipesAPI,
    ...CampaignsAPI,
    ...AudiencesAPI,
    ...DiscountsAPI,

    ...SurveysAPI,



    ...ScheduledReportsAPI,
    ...ReviewsAPI,


    ...GeneralAPI,
    ...PersonasAPI,
    ...MediaAPI,


}

export type UIDOption = { uid?: string }

export interface FetchOptions extends UIDOption {
    method?: typeof methods[number];
    payload?: any;
    opts?: any;
    signal?: any;
    forceToken?: string;
    fullResponse?: boolean;
    timeout?: number;
    retries?: number;
    retryDelay?: number;
    enableCaching?: boolean;
    noxhost?: boolean;
    asBlob?: boolean;
    queryParams?: { [key: string]: any };
    headers?: { [key: string]: any };
    // [other: string]: any;
};

export async function fetchAPI<T = any>(url: string, options: FetchOptions = {}): Promise<T> {
    const defaultOptions: FetchOptions = {
        timeout: undefined,
        retryDelay: 1000,
        enableCaching: false,
        ...options?.opts,
    };

    options = { ...defaultOptions, ...options, ...options?.opts };

    // if url starts with a /, add /api/v1
    if (url.startsWith('/') && !url.startsWith('/api/v1')) {
        let domain = utils.auth.domain;
        if (options.forceToken) {
            domain = `${utils.realDomain}/api/v1`;
            url = url.replace(":uid", options?.uid || ((utils.isLocal || utils.isStaging) ? utils.TEST_UID : undefined) || utils.uid)
        }
        url = `${domain}${url}`
    }

    url = url.replace(":uid", options?.uid || utils.uid);

    // add query params, ensuring any existing ones are preserved
    if (options.queryParams) {
        const params = new URLSearchParams(options.queryParams);
        const existingParams = new URLSearchParams(url.split('?')[1] || '');
        params.forEach((value, key) => existingParams.set(key, value));
        url = `${url.split('?')[0]}?${existingParams.toString()}`;
    }

    const req: RequestInit | any = {
        body: null,
        method: options.method || 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...options.headers
        },
        cache: options.enableCaching ? 'default' : 'no-cache',
        ...options
    };

    if (options.signal) {
        req.signal = options.signal;
    }
    if (utils.auth.loggedIn()) {
        req.headers.Authorization = ((utils.isLocal || utils.isStaging) ? options.forceToken : undefined) || utils.auth.getToken();
    }
    if (utils.asHost && (!options || !options.noxhost)) {
        req.headers['X-Host'] = utils.asHost;
    }

    if (options.payload instanceof FormData || options.payload instanceof Blob) {
        req.body = options.payload;
        // biome-ignore lint/performance/noDelete: <explanation>
        delete req.headers['Content-Type'];
    } else if (typeof options.payload === 'string') {
        req.body = options.payload;
    } else if (typeof options.payload === 'function') {
        req.body = options.payload(req);
    } else if (options.payload != null) {
        req.body = JSON.stringify(options.payload);
    }

    const fetchWithRetries = async (): Promise<any> => {
        let retriesLeft = options?.retries || 0;
        let delay = options?.retryDelay || 0;
        let lastError: Error | undefined;
        let response: Response;

        do {
            try {
                response = await fetch(url, req);

                if (!response.ok) {
                    throw new Error(response.statusText);
                }

                if (!options?.asBlob) {
                    const data = await response.json();
                    return data;
                } else {
                    const data = await (options?.asBlob ? response.blob() : response.text());
                    return data || response.statusText;
                }
            } catch (error) {
                lastError = error as Error;
                retriesLeft--;

                if (retriesLeft >= 0) {
                    await new Promise(resolve => setTimeout(resolve, delay));
                    delay *= 2;
                }
            }
        } while (retriesLeft >= 0)

        // If we've made it here, all retries have failed
        throw lastError || new Error('All retries failed');
    };


    let response: any = undefined
    if (options.timeout || options.retries) {
        const promises = [fetchWithRetries()];
        if (options.timeout) {
            promises.push(new Promise((_, reject) => setTimeout(() => reject(new Error('Request timeout')), options?.timeout)));
        }
        response = await Promise.race(promises)
    } else response = await fetch(url, req);

    if (!response && options.timeout) {
        throw new Error('Request timeout');
    }

    const ct = response.headers?.get('Content-Type');
    const isJSON = !!ct && ct.startsWith('application/json');

    if (!isJSON || options.asBlob) {
        const data = (await (options.asBlob ? response.blob() : response.text())) || response.statusText;
        if (response.status > 299) {
            throw new Error(data);
        }
        return data;
    }

    let apiResp = await response.json();

    if (!apiResp) {
        throw new Error((await response.text()) || response.statusText);
    }

    if (utils.isStaging) {
        apiResp = JSON.parse(JSON.stringify(apiResp ?? {})?.replaceAll("cdn.aiqstaging", "lab.aiqstaging") ?? '');
    }

    if (Array.isArray(apiResp.errors)) {
        const errorMessages = apiResp.errors
            .map((err: { message: any }) => ('message' in err ? err.message : err))
            .filter((m: any) => !!m)
            .join('\n');
        throw new Error(errorMessages);
    }
    return options.fullResponse ? apiResp : ('data' in apiResp ? apiResp.data : apiResp);
}

// @ts-ignore
window.fetchAPI = fetchAPI;

type MultiInput = { url: string, options?: FetchOptions } | string;
export async function fetchMulti<T = any>(input: MultiInput[], allOptions: FetchOptions = {}): Promise<T[]> {
    const promises = input.map((url: MultiInput) => {
        if (typeof url === 'string') {
            url = { url };
        }
        return fetchAPI(url.url, { ...allOptions, ...url.options });
    });
    return await Promise.all(promises);
}

interface AsyncErrorWrapperOptions<T> {
    timeFunc?: boolean;
    cancelError?: boolean; // don't show the error message

    errorCallback?: (error: any) => T | void;
    successCallback?: (value: any) => T | void;
    finallyCallback?: (wasError: boolean, currentValue?: T) => T | void;
}

export async function asyncWrap<T>(
    callback: ((() => Promise<T>) | (() => T)),
    options?: AsyncErrorWrapperOptions<T> | ((err: any) => void)
): Promise<T | undefined> {
    let value: T | undefined, wasError = false;
    try {
        const cb: any = (typeof options !== 'function' && options?.timeFunc) ? timeUtils.timeAsync(callback) : callback;
        value = await cb()
    } catch (error) {
        wasError = true;
        if (typeof options === 'function') options(error)
        else {
            if (!options?.cancelError) utils.showErr(error)
            if (options?.errorCallback) value = options?.errorCallback(error) ?? value
        }
    } finally {
        if (!wasError && (typeof options !== 'function' && options?.successCallback)) value = options?.successCallback(value) ?? value
        if (typeof options !== 'function' && options?.finallyCallback) value = options?.finallyCallback(wasError, value) ?? value
    }
    return value
}

